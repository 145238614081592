import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";

export class MsigValidators {
    
    public static IdentityNumberRequirementValidator (formGroup: FormGroup) {
        // console.log(formGroup)
        const identityTypeCtrl = formGroup.get('nationality');
        const nricCtrl = formGroup.get('nric');
        const passportCtrl = formGroup.get('passport');
        
        if (identityTypeCtrl.value === 'Malaysian') {
            if (!nricCtrl) return null;
            if (!nricCtrl.value) {
                return { nricRequired: true }
            }
        }

        if (identityTypeCtrl.value === 'Non-Malaysian') {
            if (!passportCtrl) return null;
            if (!passportCtrl.value) {
                return { passportRequired: true }
            }
        }
        
        return null;
    }

    public static Email (formControl: AbstractControl): ValidationErrors | null {
        if (!formControl.value) return null;
        const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        return emailRegEx.test(formControl.value) ? null : { email: true };
    }

    public static Nric (formControl: AbstractControl): ValidationErrors | null {
        const value = formControl.value;
        if (!value) return null;

        const nricRegex = /^\d{6}-\d{2}-\d{4}$/g;
        if (nricRegex.test(value)) return null;

        // Need DOB validation?
        
        return { nric: true }
    }

    public static CreditCardExp (formControl: AbstractControl): ValidationErrors | null {
        const value = formControl.value;
        if (!value) return null;

        const nricRegex = /^\d{2}\/\d{2}$/g;
        if (!nricRegex.test(value)) return { ccExp: '*Credit card expiry is invalid' };

        const [month, year] = value.split('/');
        if (month < 1 || month > 12) return { ccExp: '*Credit card expiry month must be between 1-12' };

        const fullYear = `${new Date().getFullYear().toString().slice(0, 2)}${year}`;
        if (parseInt(fullYear) < new Date().getFullYear()) return { ccExp: `*Credit card expiry year must be more than ${new Date().getFullYear()}` };
        
        return null;
    }

    public static CreditCard (formControl: AbstractControl): ValidationErrors | null {
        const value = formControl.value;
        if (!value) return null;

        if (value.length < 16) return { cc: '*Insufficient length' };

        const nricRegex = /^(2|4|5){1}\d{15}$/g;
        if (!nricRegex.test(value)) return { cc: '*Please enter valid card number' };
        
        return null;
    }

}